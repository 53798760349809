const state = {
	token: window.localStorage.getItem('token') || '',
	userInfo: JSON.parse(window.localStorage.getItem("userInfo")) || {},
	isLogin: JSON.parse(window.localStorage.getItem("isLogin")) || false,
	menuList:[]
}

const getters = {
	token: state => state.token,
	userInfo: state => state.userInfo,
	isLogin: state => state.isLogin,
	menuList: state => state.menuList
}
const actions = {

}
const mutations = {
	// token
	getToken(state, data) {
		state.token = data;
		window.localStorage.setItem('token', data);
		state.isLogin = true;
		window.localStorage.setItem('isLogin', true);
	},
	// 用户信息
	getInfo(state, data) {
		state.userInfo = data;
		window.localStorage.setItem('userInfo', JSON.stringify(data));
	},
	menuList(state,data){
		state.menuList = data;
	},
	// 退出登录
	clearLogin(state) {
		state.token = '';
		state.userInfo = {};
		window.localStorage.removeItem('token');
		window.localStorage.removeItem('userInfo');
		window.localStorage.removeItem('isLogin');
	},
}

export default {
	state,
	mutations,
	actions,
	getters
}