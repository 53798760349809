<template>
  <div>
    <el-container>
      <el-aside :style="{ height: contHeight + Number(100) + 'px' }" :width="widthNum">
        <div style="height: 100%" class="frame-nav">
          <el-menu
            style="height: 100%"
            :default-active="active"
            class="el-menu-vertical-demo"
            background-color="#ffffff"
            :unique-opened="true"
            router
            text-color="#575757"
            :collapse="isCollapse"
            active-text-color="#ed1c24"
          >
            <template>
              <el-menu-item index="0" disabled>
                <el-image style="width: 50px; height: 50px" :src="require('../assets/image/logo.png')" fit="contain"></el-image>
                <template slot="title">
                  <div style="font-size: 24px; display: inline-block; font-weight: bold; margin-left: 10px">OFICE</div>
                </template>
              </el-menu-item>
            </template>
            <template v-for="(item, index) in list">
              <template v-if="item.children">
                <el-submenu :index="item.path" :key="index">
                  <template slot="title">
                    <i :class="item.icon" style="margin-right: 20px"></i>
                    <span>{{ item.title }}</span>
                  </template>
                  <template v-for="(subItem, idx) in item.children">
                    <el-submenu v-if="subItem.children" :index="subItem.path" :key="idx">
                      <el-menu-item v-for="(threeItem, i) in subItem.children" :key="i" :index="threeItem.path">
                        {{ threeItem.title }}
                      </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :index="subItem.path" :key="idx">
                      <i :class="subItem.icon" style="margin-right: 20px"></i>
                      <span>{{ subItem.title }}</span>
                    </el-menu-item>
                  </template>
                </el-submenu>
              </template>
              <template v-else>
                <el-menu-item :index="item.path" :key="index">
                  <i :class="item.icon" style="margin-right: 20px"></i>
                  <template slot="title">{{ item.title }}</template>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <el-header height="40px" class="flex-row justify-btw" v-show="hideHeader == false">
          <div class="header-box" :style="{ width: contWidth + 'px' }">
            <div class="flex-row items-center justify-btw breadcrumb">
              <div class="flex-row items-center">
                <i class="el-icon-s-unfold" style="margin-right: 5px" @click="menuShow"></i>
                <!--面包屑列表-->
                <el-breadcrumb separator="/">
                  <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">
                    <router-link v-if="!item.breadNumber" :to="item.path">{{ item.name }}</router-link>
                    <span v-else>{{ item.name }}</span>
                  </el-breadcrumb-item>
                </el-breadcrumb>
              </div>
              <!-- 个人信息 -->
              <div>
                <el-dropdown>
                  <span class="el-dropdown-link pointer">
                    {{ userInfo.nickname }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><span @click="backLogin">退出登录</span></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <!-- <div class="tabs-card">
							<el-tabs v-model="editableTabsValue" type="card" @tab-remove="removeTab"
								@tab-click="tabClick">
								<el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title"
									:name="item.name" :closable="item.closable">
								</el-tab-pane>
							</el-tabs>
						</div> -->
          </div>
        </el-header>
        <el-main id="cont" :style="{ height: mainHeight + 'px' }">
          <router-view v-if="isRouterAlive" :style="{ height: mainHeight - 20 + 'px' }"> </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'frameWork',
  data() {
    return {
      //用户信息
      userInfo: this.$store.state.user.userInfo,
      // 面包屑
      breadList: this.$store.state.communal.breadList,
      // 内容
      contHeight: 0,
      mainHeight: 0,
      contWidth: 0,
      isRouterAlive: true,
      // 菜单列表
      list: [],
      active: this.$store.state.communal.activeIndex,
      isCollapse: false,
      widthNum: '240px',
      // 标签
      // tags: this.$store.state.communal.tagList,
      // currentMenu: 0,
      // nextText: 0,
      // visible: false,
      // left: 0,
      // top: 0,
      // tagsIndex: 0,
      // tagQindex: 0,

      // ==========================================
      editableTabsValue: this.$store.state.communal.activeIndex, //tab选中name
      editableTabs: this.$store.state.communal.tagList, //tab数据
      hideHeader: false
    }
  },
  directives: {
    'horizontal-scroll': {
      bind: function (el) {
        el.addEventListener('wheel', function (event) {
          event.preventDefault()
          el.scrollLeft += event.deltaY
        })
      }
    }
  },
  watch: {
    // 监听路由的变化
    $route(newVal) {
      console.log(newVal.path)
      if (newVal.path == '/dataScreening') {
        console.log(11111111)
        this.hideHeader = true
      } else {
        this.hideHeader = false
      }
      this.getBreadcrumb()
      let data = {
        name: newVal.path,
        title: newVal.meta.title,
        closable: true
      }
      if (newVal.query.item) {
        this.$set(data, 'item', newVal.query.item)
      }
      this.active = newVal.meta.active || newVal.path
      this.$forceUpdate()
      this.addTab(data)
      this.reload()
    },
    '$store.state.user.menuList'(newVal) {
      this.list = newVal
    },
    '$store.state.user.tagList'(newVal) {
      this.editableTabs = newVal
    }
  },
  created() {
    this.contWidth = window.innerWidth - 240
    this.contHeight = window.innerHeight - 101
    this.mainHeight = this.contHeight
    window.addEventListener('resize', this.getHeight)
    this.menuData()
    this.getBreadcrumb()
  },
  mounted() {
    if (this.editableTabs.length == 0) {
      let data = {
        title: '用户管理',
        name: '/UserList',
        closable: false
      }
      this.$store.commit('add_tabs', data)
      this.$store.commit('set_active_index', '/UserList')
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 自适应高度
    getHeight() {
      this.contHeight = window.innerHeight - 101
      this.mainHeight = this.contHeight
    },
    // 菜单列表
    menuData() {
      let that = this
      that.$tools.menuData(function (callback) {
        that.active = that.$route.meta.active || that.$route.path
        that.list = callback
        that.$store.commit('menuList', callback)
      })
    },
    // 菜单展示效果
    menuShow() {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        this.widthNum = '65px'
        this.contWidth += 175
      } else {
        this.widthNum = '240px'
        this.contWidth -= 175
      }
    },
    removeTab(targetName) {
      // this.$store.commit('delete_tabs', targetName);
      // let that = this;
      this.editableTabs.forEach((item, index) => {
        if (targetName == item.name) {
          if (item.item) {
            this.$router.push({
              path: this.editableTabs[index - 1].name,
              query: {
                item: JSON.stringify(this.editableTabs[index - 1].item)
              }
            })
          } else {
            this.$router.push({
              path: this.editableTabs[index - 1].name
            })
          }
          // setTimeout(function() {
          this.$store.commit('delete_tabs', targetName)
          // }, 300)
        }
      })

      // let index = this.editableTabs.length - 1;
      // let item = this.editableTabs[index];
      // this.$forceUpdate();
      // if (item.id) {
      // 	this.$router.push({
      // 		path: item.name,
      // 		query: {
      // 			id: Number(item.id)
      // 		}
      // 	})
      // } else {
      // 	this.$router.push({
      // 		path: item.name
      // 	})
      // }
    },
    tabClick(targetName) {
      this.$store.commit('set_active_index', targetName.name)
      this.editableTabs.forEach((item, index) => {
        if (targetName.index == index) {
          // if (item.id) {
          // 	this.$router.push({
          // 		path: item.name,
          // 		query: {
          // 			id: item.id
          // 		}
          // 	})
          // } else
          if (item.item) {
            this.$router.push({
              path: item.name,
              query: {
                item: item.item
              }
            })
          } else {
            this.$router.push({
              path: item.name
            })
          }
        }
      })
    },

    //新增tab
    addTab(tabItem) {
      // let tabs = this.editableTabs;
      var isExist = false
      this.editableTabs.forEach((tab) => {
        if (tab.name === tabItem.name) {
          isExist = true
        }
      })
      if (isExist) {
        this.$store.commit('set_active_index', tabItem.name)
        this.editableTabsValue = this.$store.state.communal.activeIndex
        this.editableTabs.forEach((tab) => {
          if (tab.name === tabItem.name) {
            if (!this.$route.query.item) {
              delete tab['item']
              this.$store.commit('edit_tabs', this.editableTabs)
            } else {
              this.$set(tab, 'item', this.$route.query.item)
              this.$store.commit('edit_tabs', this.editableTabs)
            }
          }
        })
      } else {
        let data = {
          title: tabItem.title,
          name: tabItem.name,
          closable: tabItem.closable
        }
        if (tabItem.item) {
          this.$set(data, 'item', tabItem.item)
        }
        this.$store.commit('add_tabs', data)
        this.$store.commit('set_active_index', tabItem.name)
        this.editableTabsValue = this.$store.state.communal.activeIndex
      }
    },
    // 删除标签
    handleClose(val, index) {
      let that = this
      let result = that.tags.findIndex((item) => item.title === val.title && item && item.path === val.path)
      result === -1 ? '' : that.tags.splice(result, 1)
      that.$store.commit('getTagList', that.tags)
      // console.log(that.tags)
      // 跳转到第一页
      if (that.tags.length == 0) {
        that.$router.push({
          path: that.list[0].children[0].path
        })
        that.currentMenu = 0
        that.active = that.list[0].children[0].path
        return false
      }
      // 删除最后一个
      if (index == that.tags.length) {
        that.checkTag(that.tags[index - 1], index - 1)
        return false
      }
      if (index >= 0) {
        that.checkTag(that.tags[index], index)
        return false
      }
    },
    // 删除全部
    closeAll() {
      let that = this
      that.tags = []
      that.$store.commit('getTagList', that.tags)
      that.$router.push({
        path: that.list[0].children[0].path
      })
      that.currentMenu = -1
      that.active = that.list[0].children[0].path
      that.visible = false //关闭右键菜单
    },
    // 关闭其他
    closeAther() {
      this.tags.forEach((item, index) => {
        if (index == this.tagsIndex) {
          this.checkTag(item, index)
          this.tags = []
          this.tags.push(item)
          this.$store.commit('getTagList', this.tags)
          this.visible = false //关闭右键菜单
        }
      })
      // console.log(this.tagsItem)
    },
    //标签位置
    scrollTo(type, index) {
      let that = this
      setTimeout(function () {
        let data = that.$refs.navPztion
        let navWz = data[index].offsetLeft
        let tagTq = data[index].offsetWidth
        if (type == 0) {
          //左滑
          if (index > 0) {
            that.nextText = -navWz
          }
        } else {
          //右滑
          // console.log(data.length)
          if (index != 0) {
            that.nextText = -Number(navWz - tagTq)
          }
          // -Number(navWz - tagTq)
        }
      }, 500)
    },

    // 标签展示
    getTag() {
      let that = this
      if (that.$route.meta.requireAuth) {
        let newBread = {}
        if (that.$route.query.item || that.$route.query.list || that.$route.query.status || that.$route.query.edit) {
          newBread = {
            title: that.$route.meta.titleN || that.$route.meta.title,
            path: that.$route.path,
            query: JSON.stringify(that.$route.query)
          }
        } else {
          newBread = {
            title: that.$route.meta.titleN || that.$route.meta.title,
            path: that.$route.path
          }
        }
        that.tags.push(newBread)
        let arr = []
        for (let i = 0; i < that.tags.length; i++) {
          if (arr.indexOf(that.tags[i].title) == -1) {
            arr.push(that.tags[i].title)
          } else {
            that.tags.splice(i, 1)
          }
        }
      }
      that.tags.forEach((item, index) => {
        if (item.path == that.$route.meta.active || item.path == that.$route.path) {
          that.currentMenu = index
          that.active = that.$route.meta.active || item.path
          // setTimeout(function() {
          // 	that.scrollTo(1, index);
          // }, 100)
          // if (index < 7) {
          // 	setTimeout(function() {
          // 		that.scrollTo(1, index);
          // 	}, 100)
          // } else {
          // 	setTimeout(function() {
          // 		that.scrollTo(0, index);
          // 	}, 100)
          // }
          this.scrollTo(1, index)
        }
      })
      that.$store.commit('getTagList', that.tags)
    },
    // 标签切换
    checkTag(item, index) {
      this.currentMenu = index
      if (item.query) {
        if (item.query.item) {
          let data = JSON.parse(item.query)
          this.$tools.routerTo(item.path + '?item=' + data.item)
          return false
        }
        this.$tools.routerTo(item.path + '?query=' + item.query)
      } else {
        this.$tools.routerTo(item.path)
      }
      this.scrollTo(1, index)
    },
    // 右击选项
    openMenu(event, index) {
      event.preventDefault() //清除鼠标右键默认事件
      let that = this
      let refData = this.$refs.navPztion[index].getBoundingClientRect()
      that.top = refData.top + Number(35)
      that.left = refData.left
      that.visible = true //显示菜单
      that.tagsIndex = index
    },
    // 面包屑处理
    getBreadcrumb() {
      let that = this
      // 由于本项目大部分属于‘一级’页面，所以在设置路由时候，一级页面不设置breadNumber = 1，‘二级’页面以上才设置breadNumber
      if (this.$route.meta.requireAuth) {
        let breadNumber = typeof this.$route.meta.breadNumber !== 'undefined' ? this.$route.meta.breadNumber : 1
        // 获取当前页面的名字和路由，并组合成新的对象
        let newBread = {
          name: that.$route.meta.titleN || this.$route.meta.title,
          path: this.$route.path,
          breadNumber: this.$route.meta.breadNumber
        }
        let vuexBreadList = [] // 默认初始化面包屑数据
        if (breadNumber !== 1) {
          // 当前面包屑breadNumber大于1时才会从vuex中获取值
          vuexBreadList = that.$store.state.communal.breadList // 获取breadList数组
        }
        if (breadNumber > vuexBreadList.length) {
          // 添加面包屑----判断条件：当前路由breadNumber大于vuexBreadList的长度
          vuexBreadList.push(newBread)
        }
        if (breadNumber < vuexBreadList.length) {
          // "回退" 面包屑----判断条件：当前路由breadNumber小于vuexBreadList的长度
          vuexBreadList.splice(breadNumber - vuexBreadList.length, vuexBreadList.length - breadNumber)
        }
        //处理完数据后， 将最终的数据更新为新的面包屑数组
        that.breadList = vuexBreadList
        // 处理完数据后，将最终的数据更新到vuex（用于页面刷新）
        that.$store.commit('getBreadList', vuexBreadList)
      }
      // if (that.$route.path == "/homePage") {
      // 	that.breadList = [];
      // 	that.$store.commit('getBreadList', []);
      // }
    }, // 面包屑点击事件
    // breadcrumbClick(item) {
    // 	this.$tools.routerTo(item.path);
    // },
    // 退出登录
    backLogin() {
      let that = this
      that.$store.commit('clearNav')
      that.$store.commit('clearLogin')
      setTimeout(function () {
        that.$router.replace({
          path: '/'
        })
      }, 100)
    }
  }
}
</script>

<style lang="scss">
.close-tags {
  position: fixed;
  z-index: 9;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 10px 0 #ccc;
  line-height: 30px;
  color: #666;

  .el-icon-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.tags-cont {
  // padding: 0 10px;
  border-top: 1px solid #efefef;
  position: relative;
  overflow: hidden;

  .tags-scoll {
    // position: relative;
    // display: flex;
    // flex: auto;
    // align-self: stretch;
    // overflow: hidden;
    // white-space: nowrap;
    // transform: translate(0);
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);

    .scroll-cont {
      overflow-x: scroll;

      .ant-tabs-nav-list {
        // overflow-x: scroll;
        // transition: transform .3s;
        position: relative;
        display: flex;
        transition: transform 0.3s;
        height: 100%;

        .tags-nav {
          transition: none;
          padding: 0 12px;
          border-right: 1px solid #efefef;
          position: relative;
          display: inline-block;

          .tags-key {
            font-size: 24px;
            color: #e8eaec;
            // margin-right: 5px;
          }

          .tags-text {
            color: #515a6e;
            padding: 0 10px 0 5px;
            font-size: 15px;
          }
        }
      }
    }

    .scroll-cont::-webkit-scrollbar {
      display: none;
    }
  }

  // .tags-scoll::-webkit-scrollbar {
  // 	display: none;
  // }

  .next {
    color: #666;
    background-color: #ffffff;
    line-height: 38px;
    z-index: 9;
    padding: 0 10px;
    // border-bottom: 1px solid #efefef;
  }

  .next-left {
    border-right: 1px solid #efefef;
  }

  .next-right {
    border-left: 1px solid #efefef;
  }
}

// .el-menu {
// 	border-right: 0 !important;
// }

// .el-scrollbar__wrap {
// 	overflow-x: hidden;
// }

// .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
// 	white-space: nowrap;
// }

.header-box {
  .breadcrumb {
    padding: 0 10px;
    height: 40px;
    border-bottom: 1px solid #efefef;
    line-height: 40px;
  }

  .tabs-card {
    padding: 10px 10px 0 10px;
  }
}

.title-nav {
  // width: 240px;
  // line-height: 80px;
  // text-align: center;
  // background-color: #102137;
  // color: #efefef;
  // height: 80px;
  // line-height: 80px;/
  // font-size: 1rem;
  // font-weight: bold;
}

#cont {
  // background-color: #f5f7f9;
}
</style>
