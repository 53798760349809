import api from '../../assets/api/api.js';
const state = {
	breadList: JSON.parse(window.localStorage.getItem("breadList")) || [],
	tagList: JSON.parse(window.localStorage.getItem("tagList")) || [],
	activeIndex: window.localStorage.getItem('activeIndex') || '',
	// currentMenu:'',
	// 上传文件
	headers: {
		token: window.localStorage.getItem('token')
	},
	fileAction: api.host + 'admin/article_gz/importFile', //故障
	fileGzdAction: api.host + 'admin/article_gzd/importFile', //故障灯
	fileTranslateAction: api.host + 'admin/translate/importFile', //翻译
	fileCsAction: api.host + 'admin/article_cs/importFile', //测试
	fileQlAction: api.host + 'admin/article_ql/importFile', //清零
	fileWxscAction: api.host + 'admin/article_repair/importFile', //维修手册
	fileWxspAction: api.host + 'admin/article_video/importFile', //维修视频
	fileHcAction: api.host + 'admin/article_consume/importFile', //耗材互查
	fileTzAction: api.host + 'admin/article_tz/importFile', //代码调整
	fileBrandAction: api.host + 'admin/article_brand/importFile', //品牌型号
	fileDownAction: api.host + 'admin/article_down/importFile', //下载

	http: api.host,
	// 图片上传接口
	uploadFile: api.upUrl,
	// 获取图片路径
	actionHttp: api.showImg
}

const getters = {
	breadList: state => state.breadList,
	tagList: state => state.tagList,
	// currentMenu: state => state.currentMenu
}
const actions = {

}
const mutations = {
	// 面包屑
	getBreadList(state, data) {
		state.breadList = data;
		window.localStorage.setItem('breadList', JSON.stringify(data));
	},
	// 添加tabs
	add_tabs(state, data) {
		state.tagList.push(data);
		window.localStorage.setItem('tagList',JSON.stringify(state.tagList));
	},
	// 编辑tabs
	edit_tabs(state,data){
		state.tagList = data;
		window.localStorage.setItem('tagList',JSON.stringify(state.tagList));
	},
	// 高亮tabs
	set_active_index(state, index) {
		state.activeIndex = index;
		window.localStorage.setItem('activeIndex', index);
	},
	// 删除tabs
	delete_tabs(state, data) {
		let index = 0;
		for (let option of state.tagList) {
			if (option.name === data) {
				break;
			}
			index++;
		}
		state.tagList.splice(index, 1);
		window.localStorage.setItem('tagList',JSON.stringify(state.tagList));
	},
	// getTagList(state, data) {
	// 	state.tagList = data;
	// 	window.localStorage.setItem('tagList', JSON.stringify(data));
	// },
	// 清除导航
	clearNav(state) {
		state.breadList = [];
		state.tagList = [];
		window.localStorage.removeItem('tagList');
		window.localStorage.removeItem('breadList');
	},
	// // 首页导航
	// navIndex(state, index){
	// 	state.navIndex = index;
	// 	window.localStorage.setItem('navIndex', index);
	// }
}


export default {
	state,
	mutations,
	actions,
	getters
}