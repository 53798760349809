import Vue from 'vue'
import VueRouter from 'vue-router'
import Signln from '../views/signIn.vue'
import frameWork from '../views/frame'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Signln',
		component: Signln
	},
	{
		path: '/frameWork',
		name: 'frameWork',
		component: frameWork,
		redirect: 'UserList',
		children: [{
				path: '/UserList',
				name: 'UserList',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/user/user_list.vue'),
				meta: {
					requireAuth: true,
					title: '用户管理'
				}
			},
			{
				path: '/dataScreening',
				name: 'dataScreening',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/data_screening/data_screening.vue'),
				meta: {
					requireAuth: true,
					title: '数据总览'
				}
			},
			{
				path: '/UserAdd',
				name: 'UserAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/user/user_add.vue'),
				meta: {
					requireAuth: true,
					title: '编辑用户',
					breadNumber: 2,
					active: '/UserList'
				}
			},
			{
				path: '/adminGroup',
				name: 'adminGroup',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/group/admin_group.vue'),
				meta: {
					requireAuth: true,
					title: '角色管理'
				}
			},
			{
				path: '/settings',
				name: 'settings',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/settings/index.vue'),
				meta: {
					requireAuth: true,
					title: '系统设置'
				}
			},
			{
				path: '/groupAdd',
				name: 'groupAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/group/group_add.vue'),
				meta: {
					requireAuth: true,
					breadNumber: 2,
					title: '角色操作',
					active: '/adminGroup'
				}
			},
			{
				path: '/purview',
				name: 'purview',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/purview/purview_list.vue'),
				meta: {
					requireAuth: true,
					title: '权限菜单'
				}
			},
			{
				path: '/ruleShow',
				name: 'ruleShow',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/purview/rule_show.vue'),
				meta: {
					requireAuth: true,
					title: '菜单编辑',
					breadNumber: 2,
					active: '/purview'
				}
			},
			{
				path: '/singlePage',
				name: 'singlePage',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/single_page/single_page.vue'),
				meta: {
					requireAuth: true,
					title: '单页管理'
				}
			},
			{
				path: '/setUpList',
				name: 'setUpList',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/setUp/setUp_list.vue'),
				meta: {
					requireAuth: true,
					title: '系统设置',
					icon: 'el-icon-setting'
				}
			},
			{
				path: '/Journal',
				name: 'Journal',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/system_management/daily_record/daily_record.vue'),
				meta: {
					requireAuth: true,
					title: '操作日志'
				}
			},
			{
				path: '/Advertising',
				name: 'Advertising',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/ad_management/advertising/advertising_list.vue'),
				meta: {
					requireAuth: true,
					title: '广告管理'
				}
			},
			{
				path: '/AdBanner',
				name: 'AdBanner',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/ad_management/advertising/adBanner_add.vue'),
				meta: {
					requireAuth: true,
					title: '广告编辑',
					breadNumber: 2,
					active: '/Advertising'
				}
			},
			{
				path: '/AdvertisingType',
				name: 'AdvertisingType',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/ad_management/advertising/advertising_type.vue'),
				meta: {
					requireAuth: true,
					title: '广告类别管理'
				}
			},
			{
				path: '/VipList',
				name: 'VipList',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/vip_management/user_vip_list.vue'),
				meta: {
					requireAuth: true,
					title: '用户管理'
				}
			},
			{
				path: '/IntegralLog',
				name: 'IntegralLog',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/integral_management/log_list.vue'),
				meta: {
					requireAuth: true,
					title: '积分明细'
				}
			},
			{
				path: '/Order',
				name: 'Order',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/order_management/order_list.vue'),
				meta: {
					requireAuth: true,
					title: '充值记录'
				}
			},
			{
				path: '/Product',
				name: 'Product',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/integral_management/product_list.vue'),
				meta: {
					requireAuth: true,
					title: '充值规则'
				}
			},
			{
				path: '/VipSetting',
				name: 'VipSetting',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/vip_management/setting_vip.vue'),
				meta: {
					requireAuth: true,
					title: '会员设置'
				}
			},
			{
				path: '/Column',
				name: 'Column',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/column_management/column_list.vue'),
				meta: {
					requireAuth: true,
					title: '栏目管理'
				}
			},
			{
				path: '/Feedback',
				name: 'Feedback',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/feedback_management/feedback_list.vue'),
				meta: {
					requireAuth: true,
					title: '反馈管理'
				}
			},
			{
				path: '/NoticeList',
				name: 'NoticeList',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/notice_management/notice_list.vue'),
				meta: {
					requireAuth: true,
					title: '通知公告'
				}
			},
			{
				path: '/HotList',
				name: 'HotList',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/hot_management/hot_list.vue'),
				meta: {
					requireAuth: true,
					title: '热门文章'
				}
			},
			{
				path: '/Textbook',
				name: 'Textbook',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/study_management/textbook/textbook_list.vue'),
				meta: {
					requireAuth: true,
					title: '学习教材'
				}
			},
			{
				path: '/TextbookAdd',
				name: 'TextbookAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/study_management/textbook/textbook_add.vue'),
				meta: {
					requireAuth: true,
					title: '教材编辑',
					breadNumber: 2,
					active: '/Textbook'
				}
			},
			{
				path: '/Video',
				name: 'Video',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/study_management/video/video_list.vue'),
				meta: {
					requireAuth: true,
					title: '学习视频'
				}
			},
			{
				path: '/VideoAdd',
				name: 'VideoAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/study_management/video/video_add.vue'),
				meta: {
					requireAuth: true,
					title: '视频编辑',
					breadNumber: 2,
					active: '/Video'
				}
			},
			{
				path: '/Application',
				name: 'Application',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/study_management/application/application_list.vue'),
				meta: {
					requireAuth: true,
					title: '活动列表'
				}
			},
			{
				path: '/ApplicationUser',
				name: 'ApplicationUser',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/study_management/application/application_user.vue'),
				meta: {
					requireAuth: true,
					title: '报名列表',
					breadNumber: 2,
					active: '/Application'
				}
			},
			{
				path: '/ApplicationAdd',
				name: 'ApplicationAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/study_management/application/application_add.vue'),
				meta: {
					requireAuth: true,
					title: '活动编辑',
					breadNumber: 2,
					active: '/Application'
				}
			},
			{
				path: '/Brand',
				name: 'Brand',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/brand_list.vue'),
				meta: {
					requireAuth: true,
					title: '品牌型号管理'
				}
			},
			{
				path: '/ArticleGz',
				name: 'ArticleGz',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/gz_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '故障内容列表'
				}
			},
			{
				path: '/ArticleGzd',
				name: 'ArticleGzd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/gzd_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '故障灯列表'
				}
			},
			{
				path: '/ArticleGzdAdd',
				name: 'ArticleGzdAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/gzd_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '故障灯编辑',
					breadNumber: 2,
					active: '/ArticleGzd'
				}
			},
			{
				path: '/ArticleGzAdd',
				name: 'ArticleGzAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/gz_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '故障内容编辑',
					breadNumber: 2,
					active: '/ArticleGz'
				}
			},
			{
				path: '/ArticleCs',
				name: 'ArticleCs',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/cs_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '测试内容列表'
				}
			},
			{
				path: '/ArticleCsAdd',
				name: 'ArticleCsAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/cs_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '测试内容编辑',
					breadNumber: 2,
					active: '/ArticleCs'
				}
			},
			{
				path: '/ArticleQl',
				name: 'ArticleQl',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/ql_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '清零代码列表'
				}
			},
			{
				path: '/ArticleQlAdd',
				name: 'ArticleQlAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/ql_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '清零代码编辑',
					breadNumber: 2,
					active: '/ArticleQl'
				}
			},
			{
				path: '/ArticleSelect',
				name: 'ArticleSelect',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/select_management/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '用户查询记录列表'
				}
			},
			{
				path: '/Repair',
				name: 'Repair',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/repair_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '维修手册列表'
				}
			},
			{
				path: '/UsersError',
				name: 'UsersError',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/userserror_management/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '上报错误'
				}
			},
			{
				path: '/Translate',
				name: 'Translate',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/translate_management/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '翻译对照列表'
				}
			},
			{
				path: '/RepairAdd',
				name: 'RepairAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/repair_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '维修手册编辑',
					breadNumber: 2,
					active: '/Repair'
				}
			},
			{
				path: '/ArtVideo',
				name: 'ArtVideo',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/video_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '维修视频列表'
				}
			},
			{
				path: '/VideoType',
				name: 'VideoType',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/video_fault/type_list.vue'),
				meta: {
					requireAuth: true,
					title: '视频类别'
				}
			},
			{
				path: '/ArtVideoAdd',
				name: 'ArtVideoAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/video_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '维修视频编辑',
					breadNumber: 2,
					active: '/ArtVideo'
				}
			},
			{
				path: '/ArticleDown',
				name: 'ArticleDown',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/down_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '下载列表'
				}
			},
			{
				path: '/ArticleDownAdd',
				name: 'ArticleDownAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/down_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '下载编辑',
					breadNumber: 2,
					active: '/ArticleDown'
				}
			},
			{
				path: '/ArticleConsume',
				name: 'ArticleConsume',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/consume_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '耗材互查列表'
				}
			},
			{
				path: '/ArticleConsumeAdd',
				name: 'ArticleConsumeAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/consume_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '耗材互查编辑',
					breadNumber: 2,
					active: '/ArticleConsume'
				}
			},
			{
				path: '/ConsumeType',
				name: 'ConsumeType',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/consume_fault/type_list.vue'),
				meta: {
					requireAuth: true,
					title: '耗材类别',
					breadNumber: 2,
					active: '/ArticleConsume'
				}
			},
			{
				path: '/ArticleTz',
				name: 'ArticleTz',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/tz_fault/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '代码调整列表'
				}
			},
			{
				path: '/ArticleTzAdd',
				name: 'ArticleTzAdd',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/fault_management/tz_fault/article_add.vue'),
				meta: {
					requireAuth: true,
					title: '代码调整编辑',
					breadNumber: 2,
					active: '/ArticleTz'
				}
			},
			{
				path: '/UploadFile',
				name: 'UploadFile',
				component: () => import( /* webpackChunkName: "frameWork" */
					'../views/up_management/article_list.vue'),
				meta: {
					requireAuth: true,
					title: '上传内容管理'
				}
			}
		]
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router