import axios from 'axios'
const HOST = "https://api.hfofice.com/";
// const imgUrl = 'https://weisou.chengduziyi.com/admin/Uploads/uploadFile';
// const lookImg = 'https://community-supermarket.oss-cn-chengdu.aliyuncs.com/';
const imgUrl = HOST + 'admin/Uploads/uploadFile';
const lookImg = 'http://ofice-private.oss-rg-china-mainland.aliyuncs.com/';
// const HOST = "/api/";

const service = axios.create({
	baseURL: HOST, //域名
	timeout: 60000, // 设置请求超时时间
});
service.host = HOST;
service.upUrl = imgUrl;
service.showImg = lookImg;

import {
	Message
} from 'element-ui';
import store from '@/store'
// request interceptor  请求之前的拦截器
service.interceptors.request.use(config => {
	if (!(config.headers['Content-Type'])) {
		if (config.method == 'post') {
			for (var key in config.data) {
				if (config.data[key] === '') {
					delete config.data[key]
				}
			}
		}
	}
	const token = window.localStorage.getItem('token');
	// const token = '62906c8be59e567f94567615548cddb5';
	// 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
	if (token) {
		config.headers['token'] = token
	}
	return config
}, error => {
	Promise.reject(error)
})
// respone interceptor 请求之后异常状态拦截
service.interceptors.response.use(
	response => {
		const res = response.data;
		if (res.code == 200) {
			return res;
		} else if (res.code == -201) { // 未登录	
			Message({
				message: res.msg,
				type: 'warning'
			})
			store.commit('clearNav');
			store.commit('clearLogin');
			window.location.href = "/";
			return false;
		} else if (res.code == -203) { // 无操作权限
			Message({
				message: res.msg,
				type: 'warning'
			})
			return false;
		} else {
			Message({
				message: res.msg,
				type: 'warning'
			})
		}
	},
	error => {
		Message({
			message: error,
			type: 'error'
		});
	}
)

export default service