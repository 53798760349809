import router from '@/router'
import apiList from '../api/https.js'
// import store from '@/store/index'
export default {
	// 页面跳转
	routerTo(path) {
		if (path) {
			if (~path.indexOf('http') || ~path.indexOf('www')) {
				window.open(path, '_blank') //'_self'当前页面，'_blank'新开页面
				return false
			} else {
				router.push({
					path: path
				})
			}
		}
	},
	// 数组去重
	unique(arr, callback) {
		let result = {};
		let finalResult = [];
		for (let i = 0; i < arr.length; i++) {
			result[arr[i]] = arr[i];
			// arr[i].month 不能重复,达到去重效果,且这里必须知晓"month"或是其他键名
		}
		for (const item in result) {
			finalResult.push(result[item]);
		}
		callback(finalResult) //要返回的数据
	},
	// 权限列表
	ruleData(back){
		apiList.adminRule({}).then(res=>{
			if(res){
				back(res.list);
			}
		})
	},
	ruleDatas(back) {
		// let that = this;
		let data = {
			content: [],
			list: []
		}
		// ================================
		var listNew = [],
			list = [],
			listT = [];
		// ================================
		apiList.adminRule({}).then(res => {
			if(res){
				console.log(res);
			}
			let oldarray = res.list;
			// 一级
			oldarray.forEach((item) => {
				if (item.auth_open == 1) {
					item.auth_openS = true;
				} else {
					item.auth_openS = false;
				}
				if (item.status == 1) {
					item.statusS = true;
				} else {
					item.statusS = false;
				}
				if (item.pid === 0) {
					item.children = [];
					listNew.push(item)
				} else {
					list.push(item)
				}
			})
			// 二级
			list.forEach((item) => {
				listNew.forEach((res) => {
					if (item.pid === res.id) {
						item.children = [];
						res.children.push(item)
					} else {
						// 三级
						listT.push(item);
						listT.forEach((rest) => {
							res.children.forEach((resp) => {
								if (rest.pid === resp.id) {
									rest.addStatus = true
									resp.children.push(rest);
									resp.children = [...new Set(resp
										.children)]; // 数组去重
	
								}
							})
						})
					}
				})
			})
			data.content = listNew;
			data.list = res.list;
			back(data)
		})
	},
	//品牌型号
	brandList(pageData,back){
		let data = {
			content: [],
			list: [],
			count:0
		}
		// ================================
		var listNew = [],
			list = [];
		apiList.brandList(pageData).then(res => {
			if (res) {
				let arrList = res.data.list;
				arrList.forEach(item=>{
					if (item.pid === 0) {
						item.children = [];
						listNew.push(item)
					} else {
						list.push(item)
					}
				})
				// 二级
				list.forEach((item) => {
					listNew.forEach((res) => {
						if (item.pid === res.id) {
							res.children.push(item)
						}
					})
				})
				data.content = listNew;
				data.list = res.list;
				data.count = res.data.count;
				back(data)
			}
		})
	},
	// 用户权限菜单
	menuData(back){
		apiList.menuList({}).then(res => {
			if (res) {
				res.list.forEach(item => {
					if (item.path == null || item.path == '' || item.path == undefined) {
						item.path = item.id.toString();
					}
					if (item.children.length == 0) {
						delete item['children']
					}
				})
				back(res.list)
			}
		})
		
	}
}