import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 公用css
import './assets/css/index.css'

// 监听el-table滚动插件
import elTableInfiniteScroll from 'el-table-infinite-scroll'
Vue.use(elTableInfiniteScroll)
// 公用js
import tools from './assets/js/tools.js'
Vue.prototype.$tools = tools

// ElementUI框架
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 封装接口
import apiList from './assets/api/https.js'
Vue.prototype.$api = apiList

// 时间戳转换
import moment from 'moment'
Vue.prototype.$moment = moment

// 富文本css
import '../public/static/tinymce/skins/content/default/content.css'

Vue.config.productionTip = false

// 处理重复路由bug
import Router from 'vue-router'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
// const Base64 = require('js-base64').Base64

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
