<template>
	<div class="box flex-column items-center justify-center" :style="{ height: contHeight+'px' }">
		<div class="flex-row items-center justify-center logo-box margin-bottom">
			<el-image class="logo-size" :src="require('../assets/image/logo.png')" fit="fill"></el-image>
			<h1>后台管理</h1>
		</div>
		<div class="flex-column items-center justify-center form-cont">
			<el-form label-position="left" label-width="80px" :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="账号" prop="username">
					<el-input v-model="ruleForm.username"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="ruleForm.password" type="password"></el-input>
				</el-form-item>
				<!-- <el-form-item>
					<div>
						<el-checkbox v-model="single">记住密码</el-checkbox>
					</div>
				</el-form-item> -->
				<el-form-item>
					<el-button style="width: 50%;" type="danger" @click="submitForm('ruleForm')">登录</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		name:'signIn',
		data() {
			return {
				contHeight: 0,
				ruleForm: {
					username: '',
					password: ''
				},
				rules: {
					username: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				single: false,
				token:window.localStorage.getItem('token'),
				isLogin:this.$store.state.user.isLogin
				
			}
		},
		created() {

		},
		mounted() {
			
			this.contHeight = window.innerHeight;
			window.addEventListener('resize', this.getHeight);
			// 判断是否记住密码
			if (!window.localStorage.getItem('user') && !window.localStorage.getItem('pass')) {
				this.ruleForm.username = '';
				this.ruleForm.password = '';
			} else {
				this.ruleForm.username = window.localStorage.getItem('user');
				this.ruleForm.password = window.localStorage.getItem('pass');
			}
			// this.$tools.ruleData(function(res){
			// 	console.log(res)
			// })
			if(this.isLogin){
				this.$router.push({
					path:'/frameWork'
				})
			}else{
				return false;
			}
		},
		methods: {
			// 自适应高度
			getHeight() {
				this.contHeight = window.innerHeight;
			},
			// 登录
			submitForm(formName) {
				let that = this;
				let data = {
					username: that.ruleForm.username,
					password: that.ruleForm.password
				}
				if (this.single == true) {
					window.localStorage.setItem('user', this.ruleForm.username);
					window.localStorage.setItem('pass', this.ruleForm.password);
				} else {
					window.localStorage.removeItem('user');
					window.localStorage.removeItem('pass');
				}
				that.$refs[formName].validate(valid => {
					if (valid) {
						that.$api.login(data).then(res => {
							if(res){
								that.$store.commit('getToken',res.token);
								that.$store.commit('getInfo',res.data);
								that.$router.replace({
									name: 'frameWork'
								})
							}
							
						})
					} else {
						return false;
					}
				})

			}
		}
	}
</script>

<style scoped lang="scss">
	.box {
		background-color: #ffffff;
		
		.logo-box{
			// box-shadow: 0 0 10px 0 #efefef;
			width: 30%;
			// padding: 2rem 3rem;
			.logo-size{
				width: 100px;
				margin-right: 15px;
				border-radius: 8px;
			}
		}
		
		.form-cont {
			box-shadow: 0 0 10px 0 #efefef;
			width: 30%;
			border-radius: 10px;
			padding: 5rem 2rem;
		}
	}
</style>