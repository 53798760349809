// 引入封装好的axios
// ps:如果没有封装，正常引入axios即可
import axios from './api'
export default {
	// 上传图片
	uploadImg(data, control) {
		return axios.post('admin/Uploads/uploadFile', data, control)
	},
	// 临时查看
	getFile(data, control) {
		return axios.post('admin/Uploads/getFile', data, control)
	},
	// =====================登录====================
	login(data, control) {
		return axios.post('admin/Login/login', data, control)
	},

	// =====================菜单权限====================
	adminRule(data, control) {
		//列表
		return axios.post('admin/Auth/adminRule', data, control)
	},
	ruleShow(data, control) {
		//详情
		return axios.post('admin/Auth/ruleShow', data, control)
	},
	ruleAdd(data, control) {
		//添加
		return axios.post('admin/Auth/ruleAdd', data, control)
	},
	ruleEdit(data, control) {
		//修改
		return axios.post('admin/Auth/ruleEdit', data, control)
	},
	ruleSelectDel(data, control) {
		//删除
		return axios.post('admin/Auth/ruleSelectDel', data, control)
	},
	// =====================用户菜单====================
	menuList(data, control) {
		return axios.post('admin/Index/index', data, control)
	},

	// ===================反馈管理===================
	OpinionList(data, control) {
		//列表
		return axios.post('admin/Opinion/getList', data, control)
	},
	OpinionShow(data, control) {
		//详情
		return axios.post('admin/Opinion/show', data, control)
	},
	OpinionEdit(data, control) {
		//修改
		return axios.post('admin/Opinion/update', data, control)
	},
	// ===================账号管理===================
	adminList(data, control) {
		//列表
		return axios.post('admin/Auth/adminList', data, control)
	},
	adminShow(data, control) {
		//详情
		return axios.post('admin/Auth/adminShow', data, control)
	},
	adminAdd(data, control) {
		//添加
		return axios.post('admin/Auth/adminAdd', data, control)
	},
	adminEdit(data, control) {
		//修改
		return axios.post('admin/Auth/adminEdit', data, control)
	},
	adminSelectDel(data, control) {
		//删除
		return axios.post('admin/Auth/adminSelectDel', data, control)
	},
	// ===================角色管理====================
	adminGroup(data, control) {
		//列表
		return axios.post('admin/Auth/adminGroup', data, control)
	},
	groupShow(data, control) {
		//详情
		return axios.post('admin/Auth/groupShow', data, control)
	},
	groupAdd(data, control) {
		//添加
		return axios.post('admin/Auth/groupAdd', data, control)
	},
	groupEdit(data, control) {
		//修改
		return axios.post('admin/Auth/groupEdit', data, control)
	},
	groupSelectDel(data, control) {
		//删除
		return axios.post('admin/Auth/groupSelectDel', data, control)
	},
	// ====================单页管理====================
	// https://weisou.chengduziyi.com/admin/Single/getList
	singleList(data, control) {
		//列表
		return axios.post('admin/Single/getList', data, control)
	},
	singleAdd(data, control) {
		//添加
		return axios.post('admin/Single/add', data, control)
	},
	singleEdit(data, control) {
		//修改
		return axios.post('admin/Single/edit', data, control)
	},
	singleDel(data, control) {
		//删除
		return axios.post('admin/Single/selectDel', data, control)
	},
	// ====================操作日志====================
	logList(data, control) {
		//列表
		return axios.post('admin/Admin_log/getList', data, control)
	},

	logSelectDel(data, control) {
		//删除
		return axios.post('admin/Admin_log/selectDel', data, control)
	},

	// ====================通知公告====================
	listNotice(data, control) {
		//列表
		return axios.post('admin/Notice/getList', data, control)
	},
	addNotice(data, control) {
		//添加
		return axios.post('admin/Notice/add', data, control)
	},
	editNotice(data, control) {
		//修改
		return axios.post('admin/Notice/update', data, control)
	},
	delNotice(data, control) {
		//删除
		return axios.post('admin/Notice/selectDel', data, control)
	},

	// ====================热门文章====================
	listHot(data, control) {
		//列表
		return axios.post('admin/article_hot/getList', data, control)
	},
	addHot(data, control) {
		//添加
		return axios.post('admin/article_hot/add', data, control)
	},
	editHot(data, control) {
		//修改
		return axios.post('admin/article_hot/update', data, control)
	},
	delHot(data, control) {
		//删除
		return axios.post('admin/article_hot/selectDel', data, control)
	},

	// ===================广告管理===================
	/*======================类别 start====================*/
	listAdType(data, control) {
		//列表
		return axios.post('admin/Ad/listAdType', data, control)
	},
	addAdType(data, control) {
		//添加
		return axios.post('admin/Ad/addAd', data, control)
	},
	editAdType(data, control) {
		//修改
		return axios.post('admin/Ad/editAdType', data, control)
	},
	delAdType(data, control) {
		//删除
		return axios.post('admin/Ad/delAdType', data, control)
	},
	/*======================类别 end====================*/

	/*======================广告图 start====================*/
	getListAd(data, control) {
		//列表
		return axios.post('admin/Ad/getList', data, control)
	},
	addAd(data, control) {
		//添加
		return axios.post('admin/Ad/add', data, control)
	},
	editAd(data, control) {
		//修改
		return axios.post('admin/Ad/edit', data, control)
	},
	selectDelAd(data, control) {
		//删除
		return axios.post('admin/Ad/selectDel', data, control)
	},
	/*======================广告图 end====================*/

	// ====================栏目管理====================
	columnList(data, control) {
		//列表
		return axios.post('admin/Menu/getList', data, control)
	},
	columnAdd(data, control) {
		//添加
		return axios.post('admin/Menu/add', data, control)
	},
	columnEdit(data, control) {
		//修改
		return axios.post('admin/Menu/updateMenu', data, control)
	},
	columnSelectDel(data, control) {
		//删除
		return axios.post('admin/Menu/selectDel', data, control)
	},

	// ====================会员用户管理====================
	vipUsersList(data, control) {
		//列表
		return axios.post('admin/Users/getList', data, control)
	},
	vipUsersEdit(data, control) {
		//修改
		return axios.post('admin/Users/edit', data, control)
	},
	vipUsersSelectDel(data, control) {
		//删除
		return axios.post('admin/Users/selectDel', data, control)
	},

	// ====================会员规则设置====================
	vipSetList(data, control) {
		//列表
		return axios.post('admin/Vip/getList', data, control)
	},
	vipSetAdd(data, control) {
		//添加
		return axios.post('admin/Vip/add', data, control)
	},
	vipSetEdit(data, control) {
		//修改
		return axios.post('admin/Vip/updateVip', data, control)
	},
	vipSetSelectDel(data, control) {
		//删除
		return axios.post('admin/Vip/selectDel', data, control)
	},
	// ====================耗材类别设置====================
	consumeTypeList(data, control) {
		//列表
		return axios.post('admin/article_consume_type/getList', data, control)
	},
	consumeTypeAdd(data, control) {
		//添加
		return axios.post('admin/article_consume_type/add', data, control)
	},
	consumeTypeEdit(data, control) {
		//修改
		return axios.post('admin/article_consume_type/update', data, control)
	},
	consumeTypeSelectDel(data, control) {
		//删除
		return axios.post('admin/article_consume_type/selectDel', data, control)
	},

	// ====================学习教材====================
	textbookList(data, control) {
		//列表
		return axios.post('admin/study_textbook/getList', data, control)
	},
	textbookAdd(data, control) {
		//添加
		return axios.post('admin/study_textbook/add', data, control)
	},
	textbookEdit(data, control) {
		//修改
		return axios.post('admin/study_textbook/updateTextbook', data, control)
	},
	textbookSelectDel(data, control) {
		//删除
		return axios.post('admin/study_textbook/selectDel', data, control)
	},
	// ====================学习视频====================

	videoList(data, control) {
		//列表
		return axios.post('admin/study_video/getListOur', data, control)
	},
	videoAdd(data, control) {
		//添加
		return axios.post('admin/study_video/add', data, control)
	},
	videoEdit(data, control) {
		//修改
		return axios.post('admin/study_video/updateVideo', data, control)
	},
	videoSelectDel(data, control) {
		//删除
		return axios.post('admin/study_video/selectDel', data, control)
	},
	videoAddList(data, control) {
		//添加
		return axios.post('admin/study_video_list/add', data, control)
	},
	videoEditList(data, control) {
		//修改
		return axios.post('admin/study_video_list/updateVideo', data, control)
	},
	videoListSelectDel(data, control) {
		//删除
		return axios.post('admin/study_video_list/selectDel', data, control)
	},

	// ====================活动报名====================
	// build.chengduziyi.com/admin/Activity/getPartList
	ActivityPartList(data, control) {
		//列表
		return axios.post('admin/Activity/getPartList', data, control)
	},
	ActivityList(data, control) {
		//列表
		return axios.post('admin/Activity/getList', data, control)
	},
	ActivityAdd(data, control) {
		//添加
		return axios.post('admin/Activity/add', data, control)
	},
	ActivityEdit(data, control) {
		//修改
		return axios.post('admin/Activity/updateActivity', data, control)
	},
	ActivitySelectDel(data, control) {
		//删除
		return axios.post('admin/Activity/selectDel', data, control)
	},
	downBrand(data, control) {
		//列表
		return axios.post('admin/article_brand/expFile', data, control)
	},
	// ======================内容管理======================
	// ====================品牌型号管理====================
	brandList(data, control) {
		//列表
		return axios.post('admin/article_brand/getList', data, control)
	},
	brandList1(data, control) {
		//列表
		return axios.post('admin/article_brand/getList1', data, control)
	},
	brandAdd(data, control) {
		//添加
		return axios.post('admin/article_brand/add', data, control)
	},
	brandEdit(data, control) {
		//修改
		return axios.post('admin/article_brand/updateBrand', data, control)
	},
	brandSelectDel(data, control) {
		//删除
		return axios.post('admin/article_brand/selectDel', data, control)
	},

	// ====================查询记录管理====================
	SelectList(data, control) {
		//列表
		return axios.post('admin/article_select/getList', data, control)
	},
	SelectSelectDel(data, control) {
		//删除
		return axios.post('admin/article_select/selectDel', data, control)
	},
	//================  基本设置  ===================//
	Configshow(data, control) {
		//详情
		return axios.post('admin/Config/getData', data, control)
	},
	ConfigEdit(data, control) {
		//修改
		return axios.post('admin/Config/setData', data, control)
	},
	// ====================翻译对照管理====================
	TranslateList(data, control) {
		//列表
		return axios.post('admin/translate/getList', data, control)
	},
	TranslateShow(data, control) {
		//详情
		return axios.post('admin/translate/show', data, control)
	},
	TranslateAdd(data, control) {
		//添加
		return axios.post('admin/translate/add', data, control)
	},
	TranslateEdit(data, control) {
		//修改
		return axios.post('admin/translate/update', data, control)
	},
	TranslateSelectDel(data, control) {
		//删除
		return axios.post('admin/translate/selectDel', data, control)
	},
	// ====================故障灯管理====================
	articleGzdList(data, control) {
		//列表
		return axios.post('admin/article_gzd/getList', data, control)
	},
	articleGzdShow(data, control) {
		//详情
		return axios.post('admin/article_gzd/show', data, control)
	},
	articleGzdAdd(data, control) {
		//添加
		return axios.post('admin/article_gzd/add', data, control)
	},
	articleGzdEdit(data, control) {
		//修改
		return axios.post('admin/article_gzd/updateArticleGzd', data, control)
	},
	articleGzdSelectDel(data, control) {
		//删除
		return axios.post('admin/article_gzd/selectDel', data, control)
	},
	// ====================故障代码管理====================
	articleList(data, control) {
		//列表
		return axios.post('admin/article_gz/getList', data, control)
	},
	articleShow(data, control) {
		//详情
		return axios.post('admin/article_gz/show', data, control)
	},
	articleAdd(data, control) {
		//添加
		return axios.post('admin/article_gz/add', data, control)
	},
	articleEdit(data, control) {
		//修改
		return axios.post('admin/article_gz/updateArticleGz', data, control)
	},
	articleSelectDel(data, control) {
		//删除
		return axios.post('admin/article_gz/selectDel', data, control)
	},

	// ====================测试代码管理====================
	articleCsList(data, control) {
		//列表
		return axios.post('admin/article_cs/getList', data, control)
	},
	articleCsShow(data, control) {
		//详情
		return axios.post('admin/article_cs/show', data, control)
	},
	articleCsAdd(data, control) {
		//添加
		return axios.post('admin/article_cs/add', data, control)
	},
	articleCsEdit(data, control) {
		//修改
		return axios.post('admin/article_cs/updateArticleGz', data, control)
	},
	articleCsSelectDel(data, control) {
		//删除
		return axios.post('admin/article_cs/selectDel', data, control)
	},
	// ====================清零代码管理====================
	articleQlList(data, control) {
		//列表
		return axios.post('admin/article_ql/getList', data, control)
	},
	articleQlShow(data, control) {
		//详情
		return axios.post('admin/article_ql/show', data, control)
	},
	articleQlAdd(data, control) {
		//添加
		return axios.post('admin/article_ql/add', data, control)
	},
	articleQlEdit(data, control) {
		//修改
		return axios.post('admin/article_ql/updateArticleGz', data, control)
	},
	articleQlSelectDel(data, control) {
		//删除
		return axios.post('admin/article_ql/selectDel', data, control)
	},

	// build.chengduziyi.com/admin/article_repair/getList
	// ====================维修手册管理====================
	repairList(data, control) {
		//列表
		return axios.post('admin/article_repair/getList', data, control)
	},
	repairShow(data, control) {
		//详情
		return axios.post('admin/article_repair/show', data, control)
	},
	repairAdd(data, control) {
		//添加
		return axios.post('admin/article_repair/add', data, control)
	},
	repairEdit(data, control) {
		//修改
		return axios.post('admin/article_repair/update', data, control)
	},
	repairSelectDel(data, control) {
		//删除
		return axios.post('admin/article_repair/selectDel', data, control)
	},
	// ====================维修视频管理====================
	ArtvideoList(data, control) {
		//列表
		return axios.post('admin/article_video/getList', data, control)
	},
	ArtvideoShow(data, control) {
		//详情
		return axios.post('admin/article_video/show', data, control)
	},
	ArtvideoAdd(data, control) {
		//添加
		return axios.post('admin/article_video/add', data, control)
	},
	ArtvideoEdit(data, control) {
		//修改
		return axios.post('admin/article_video/update', data, control)
	},
	ArtvideoSelectDel(data, control) {
		//删除
		return axios.post('admin/article_video/selectDel', data, control)
	},

	VideoTypeList(data, control) {
		//列表
		return axios.post('admin/article_video_type/getList', data, control)
	},
	VideoTypeAdd(data, control) {
		//添加
		return axios.post('admin/article_video_type/add', data, control)
	},
	VideoTypeEdit(data, control) {
		//修改
		return axios.post('admin/article_video_type/update', data, control)
	},
	VideoTypeSelectDel(data, control) {
		//删除
		return axios.post('admin/article_video_type/selectDel', data, control)
	},
	// ====================下载管理====================
	downList(data, control) {
		//列表
		return axios.post('admin/article_down/getList', data, control)
	},
	downTypeList(data, control) {
		//类比列表
		return axios.post('admin/article_down_type/getList', data, control)
	},
	downShow(data, control) {
		//详情
		return axios.post('admin/article_down/show', data, control)
	},
	downAdd(data, control) {
		//添加
		return axios.post('admin/article_down/add', data, control)
	},
	downEdit(data, control) {
		//修改
		return axios.post('admin/article_down/update', data, control)
	},
	downSelectDel(data, control) {
		//删除
		return axios.post('admin/article_down/selectDel', data, control)
	},
	// ====================耗材互查管理====================
	consumeList(data, control) {
		//列表
		return axios.post('admin/article_consume/getList', data, control)
	},
	consumeShow(data, control) {
		//详情
		return axios.post('admin/article_consume/show', data, control)
	},
	consumeAdd(data, control) {
		//添加
		return axios.post('admin/article_consume/add', data, control)
	},
	consumeEdit(data, control) {
		//修改
		return axios.post('admin/article_consume/update', data, control)
	},
	consumeSelectDel(data, control) {
		//删除
		return axios.post('admin/article_consume/selectDel', data, control)
	},

	// ====================代码调整管理====================
	articleTzList(data, control) {
		//列表
		return axios.post('admin/article_tz/getList', data, control)
	},
	articleTzShow(data, control) {
		//详情
		return axios.post('admin/article_tz/show', data, control)
	},
	articleTzAdd(data, control) {
		//添加
		return axios.post('admin/article_tz/add', data, control)
	},
	articleTzEdit(data, control) {
		//修改
		return axios.post('admin/article_tz/updateArticleGz', data, control)
	},
	articleTzSelectDel(data, control) {
		//删除
		return axios.post('admin/article_tz/selectDel', data, control)
	},
	// ====================积分管理====================
	consumptionLog(data, control) {
		//明细列表
		return axios.post('admin/Users/consumptionLog', data, control)
	},
	productList(data, control) {
		//充值规则列表
		return axios.post('admin/Product/getList', data, control)
	},
	productAdd(data, control) {
		//充值规则添加
		return axios.post('admin/Product/add', data, control)
	},
	productEdit(data, control) {
		//充值规则修改
		return axios.post('admin/Product/update', data, control)
	},
	productSelectDel(data, control) {
		//充值规则删除
		return axios.post('admin/Product/selectDel', data, control)
	},

	// ====================充值记录====================
	orderList(data, control) {
		//列表
		return axios.post('admin/Order/getList', data, control)
	},
	orderListSelectDel(data, control) {
		//删除
		return axios.post('admin/Order/selectDel', data, control)
	},
	// ====================上报错误====================
	getError(data, control) {
		//列表
		return axios.post('admin/users_error/getList', data, control)
	},
	setError(data, control) {
		//列表
		return axios.post('admin/users_error/update', data, control)
	},
	delError(data, control) {
		//删除
		return axios.post('admin/users_error/selectDel', data, control)
	},

	// ====================上传内容====================
	uploadFile(data, control) {
		//列表
		return axios.post('admin/upload_file/getList', data, control)
	},
	uploadShow(data, control) {
		//详情
		return axios.post('admin/upload_file/show', data, control)
	},
	editShow(data, control) {
		//详情
		return axios.post('admin/upload_file/update', data, control)
	},
	statistics(data, control) {
		//大屏数据
		return axios.post('admin/index/statistics', data, control)
	}
	// build.chengduziyi.com/admin/upload_file/getList
	// Uploads(control) { //删除
	// 	// return axios.post('admin/Uploads/uploadFile', {
	// 	// 	fileType
	// 	// }, control)
	// 	return request({
	// 		url: process.env.BASE_API + '/common/upload.do',
	// 		method: 'post',
	// 		data: file
	// 	})
	// },
}